import Cookies from "js-cookie";

export const getUrlParams = (input: string) => {
  const params = new URLSearchParams(input);
  return params;
};

export const getCookie = (name: string) => {
  const cookieValue = Cookies.get(name);
  return cookieValue;
};

export const setCookie = (name: string, value: string) => {
  Cookies.set(name, value, { secure: true, sameSite: "strict" });
};

export const removeCookie = (name: string) => {
  Cookies.remove(name);
};

export const setCache = (key: string, data: any) =>
  localStorage.setItem(key, JSON.stringify(data));

export const clearCache = (key: string) => localStorage.removeItem(key);

export const getCache = (key: string) => {
  const cachedData: any = localStorage.getItem(key);
  if (cachedData) {
    try {
      return JSON.parse(cachedData);
    } catch (error) {
      return null; // Return null if JSON parsing fails
    }
  }
  return null;
};

export const getDbSyncBaseEndpoint = () => {
  const projectEnv = process.env.REACT_APP_PROJECT_ENV;
  switch (projectEnv) {
    case "development":
      return "https://development.dbsync.bjshomedelivery.com";
    case "staging":
      return "https://staging.dbsync.api.bjshomedelivery.com";
    case "release_uk":
      return "https://dbsync.api.bjshomedelivery.com";
    case "release_us":
      return "https://us.dbsync.api.bjshomedelivery.com";
  }
};

export const getToolbox = () => {
  const projectEnv = process.env.REACT_APP_PROJECT_ENV;
  switch (projectEnv) {
    case "development":
      return "https://development.toolbox.bjshomedelivery.com/toolbox/recentimport";
    case "staging":
      return "https://staging.toolbox.bjshomedelivery.com/toolbox/recentimport";
    case "release_uk":
      return "https://toolbox.bjshomedelivery.com/toolbox/recentimport";
    case "release_us":
      return "https://us.toolbox.bjshomedelivery.com/toolbox/recentimport";
  }
};

