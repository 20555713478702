import { configureStore } from "@reduxjs/toolkit";
import { uiLibrarySlice } from "@bjsdistribution/dms-component-library";

import appManagerUiRenderSlice from "./features/appManagerUiRenderSlice";
import appMangerUtilitySlice from "./features/appMangerUtilitySlice";

const DmsStore = configureStore({
  reducer: {
    // App manager slices
    appManagerUiRenderSlice,
    appMangerUtilitySlice,
    // Ui libeary slice
    uiLibrarySlice,
  },
});

export default DmsStore;
