import { StyledAside } from "./AsideStyle";
import { menuData } from "./menu";
import { NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

const Aside = ({ toggleSidebar }: any) => {
  const location = useLocation();
  const [handleActive, setHandleActive] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("orders/view")) {
      setHandleActive(true);
    } else {
      setHandleActive(false);
    }
  }, [location]);

  return (
    <StyledAside>
      <div className="navigation">
        <nav style={{ position: "relative" }}>
          <ul className="main-menu">
            {menuData.map((item, id) => (
              <li onClick={() => toggleSidebar()} key={id}>
                <NavLink
                  to={item.url}
                  className={
                    item.pathMatch && handleActive ? "disabledActive" : ""
                  }
                >
                  <div className="flex flex-item-center gap10">
                    <span>{item.icon}</span>
                  </div>
                </NavLink>
                {
                  <div className="submenu">
                    <div className="subMenuCover">
                      <h2>{item.name}</h2>
                      <ul>
                        {item?.subMenu?.map((suMenu, index) => (
                          <li key={index} onClick={() => toggleSidebar()}>
                            {
                              //@ts-ignore
                              suMenu?.target ? (
                                <>
                                  <a href={suMenu?.url} target="_blank">
                                    {suMenu?.name}
                                  </a>{" "}
                                </>
                              ) : (
                                <NavLink
                                  end
                                  //@ts-ignore
                                  to={suMenu.url}
                                  className={({ isActive }) =>
                                    isActive ? "active" : ""
                                  }
                                >
                                  <div className="flex flex-item-center gap10">
                                    <span>{suMenu.name}</span>
                                  </div>
                                </NavLink>
                              )
                            }
                          </li>
                        ))}
                      </ul>
                    </div>
                    {/* <div className="toggle-icon" onClick={()=>toggleSidebar()}>
            <Collapse/>
            </div> */}
                  </div>
                }
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </StyledAside>
  );
};

export default Aside;
